import { BrowserRouter,HashRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import BlogAkilliev from "./pages/BlogDetails/BlogAkilliev";
import ProfesyonelKamera from "./pages/BlogDetails/ProfesyonelKamera";
import PersonelTakipSistemleri from "./pages/BlogDetails/PersonelTakipSistemleri";
import AlarmSistemleri from "./pages/BlogDetails/AlarmSistemleri";
import NetworkSistemleri from "./pages/BlogDetails/NetworkSistemleri";
import GecisSistemleri from "./pages/BlogDetails/GecisSistemleri";
import Contact from "./pages/Contact/Contact";
import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import Team from "./pages/Team/Team";
import TeamDetails from "./pages/TeamDetails/TeamDetails";


function App() {
  return (
    <>
      <HashRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/homeTwo" element={<HomeTwo/>} />
            <Route path="/homeThree" element={<HomeThree/>} />
            <Route path="/headerStyleFour" element={<HeaderStyleFour/>} />
            <Route path="/headerStyleFive" element={<HeaderStyleFive/>} />
            <Route path="/headerStyleSix" element={<HeaderStyleSix/>} />
            <Route path="/headerStyleSeven" element={<HeaderStyleSeven/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/blogDetails" element={<BlogDetails/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/BlogAkilliev" element={<BlogAkilliev/>} />
            <Route path="/ProfesyonelKamera" element={<ProfesyonelKamera/>} />
            <Route path="/PersonelTakipSistemleri" element={<PersonelTakipSistemleri/>} />
            <Route path="/AlarmSistemleri" element={<AlarmSistemleri/>} />
            <Route path="/NetworkSistemleri" element={<NetworkSistemleri/>} />
            <Route path="/GecisSistemleri" element={<GecisSistemleri/>} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
