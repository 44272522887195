import React from 'react';
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle, keyword }) => {
    return (
        <>
      
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle} || ARTTECH GÜVENLİK</title>
                <meta name="robots" content="index,follow" />
                <meta name="author" content="ARTTECH GÜVENLİK" />
                <meta name="description" content="ARTTECH GÜVENLİK, Akıllı Ev Sistemleri, Profesyonel Kamera Güvenlik Sistemleri, Personel Takip Sistemleri, Yangın Algılama ve Hırsız Alarm Sistemleri, Network Çözümleri" />
                <meta name="keywords" content="Akıllı Ev, Güvenlik Kamera Sistemleri, Personel Takip Sistemleri, Yangın Algılama ve Hırsız Alarm Sistemleri, Düzce Akıllı Ev, Bolu Akıllı Ev, Zonguldak Akıllı Ev, plaka tanıma sistemleri"/>
                <meta name="keywords" content={keyword}/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            
                
      {/* other stuff... */}

      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=G-B2F2PTQGJM`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "G-B2F2PTQGJM");
        `}
      </script>

            </Helmet>
        </>
  

    );
};

export default PageHelmet;